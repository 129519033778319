define(['lodash'], function (_) {
    'use strict';
    return {
        keyvalue(propertyName, stringifier) {
            return function (style, item) {
                style[propertyName] = stringifier(item); // eslint-disable-line santa/no-side-effects
                return style;
            };
        },

        map(map) {
            return function (style, item) {
                return _(map)
                    .mapValues(stringifier => stringifier(item))
                    .merge(style)
                    .value();
            };
        }
    };
});
