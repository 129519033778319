define([
    'compDesignUtils/cssItem/property',
    'compDesignUtils/cssItem/stringify',
    'compDesignUtils/cssItem/cssToken'
], function (Property, Stringify, CSSToken) {
    'use strict';

    return {
        cssBoxShadow: Property.keyvalue('boxShadow',
            Stringify.list(
                Stringify.value({
                    inset: CSSToken.KEYWORD,
                    offsetX: CSSToken.LENGTH_OR_PERCENTAGE,
                    offsetY: CSSToken.LENGTH_OR_PERCENTAGE,
                    blurRadius: CSSToken.LENGTH_OR_PERCENTAGE,
                    spreadRadius: CSSToken.LENGTH_OR_PERCENTAGE,
                    color: CSSToken.COLOR_RGBA
                }))),

        cssBorderRadius: Property.keyvalue('borderRadius',
            Stringify.value({
                topLeft: CSSToken.LENGTH_OR_PERCENTAGE,
                topRight: CSSToken.LENGTH_OR_PERCENTAGE,
                bottomRight: CSSToken.LENGTH_OR_PERCENTAGE,
                bottomLeft: CSSToken.LENGTH_OR_PERCENTAGE
            }, ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'])
        ),

        cssBorder: Property.map({
            borderWidth: Stringify.value({
                width: CSSToken.BORDER_WIDTH
            }),

            borderStyle: Stringify.value({
                style: CSSToken.BORDER_STYLE
            }),

            borderColor: Stringify.value({
                color: CSSToken.BORDER_COLOR
            })
        })
    };
});
