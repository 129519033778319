define(function () {
    'use strict';
    return {
        KEYWORD: 'keyword',
        LENGTH_OR_PERCENTAGE: 'length_or_percentage',
        COLOR_RGBA: 'rgbcolor',

        BR_WIDTH: 'br_width',
        BORDER_WIDTH: 'border_width',
        BORDER_STYLE: 'border_style',
        BORDER_COLOR: 'border_color'
    };
});
