define([
    'lodash',
    'compDesignUtils/cssItem/cssItem'
], function (_, CSSItem) {
    'use strict';

    function renderDesign(cssStyle) {
        return _(cssStyle)
            .keys()
            .filter(key => !_.isNull(cssStyle[key]) && _.has(CSSItem, key))
            .reduce((style, key) => CSSItem[key](style, cssStyle[key]), {});
    }

    function getContainerStyle(designData, useCssDesignData) {
        let containerStyle = {};
        if (useCssDesignData) {
            const cssStyle = _.get(designData, 'cssStyle');
            if (_.isPlainObject(cssStyle)) {
                containerStyle = _.assign(renderDesign(cssStyle), {
                    overflow: 'hidden'
                });
            }

            if (!_.isEmpty(containerStyle.borderRadius)) {
                _.assign(containerStyle, {transform: 'translateZ(0)'});
            }
        }
        return containerStyle;
    }

    function getMediaPlayerContainerStyle(designData) {
        const style = getContainerStyle(designData, true);

        if (!_.isEmpty(style.borderRadius)) {
            const hasBorder = !_.isEmpty(style.borderWidth);
            const hasShadow = !_.isEmpty(style.boxShadow);
            let boxShadow, rgba, color;

            // if (hasBorder || hasShadow) {
            if (hasBorder) {
                // If we have both borderRadius and actual borders and/or shadow
                // adding translateZ(0) creates an ugly hairline (נימה) in the perimeter on Webkit and friends
                // so we paint over it with the same color as the border/shadow to hide it

                if (hasBorder) {
                    // we have borders, so fix according to border color
                    rgba = _.get(designData, ['cssStyle', 'cssBorder', 'color', 'top']);
                    color = `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`;
                    boxShadow = getHairLineBoxShadowFix(color);

                    /* if (hasShadow) {
                        // TODO: we have shadow as well, so need to also fix hairline between border and shadow
                    } */
                }/* else {
                    // TODO: we have only shadow, so fix according to shadow color
                    const cssBoxShadow = _.get(designData, ['cssStyle', 'cssBoxShadow', 0]);
                    rgba = cssBoxShadow.color;
                    color = `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, ${rgba.alpha})`;
                    boxShadow = getHairLineBoxShadowFix(color);
                }*/

                _.assign(
                    style,
                    {
                        boxShadow: hasShadow ?
                            `${style.boxShadow}, ${boxShadow}` :
                            boxShadow
                    });
            }
        }

        return style;
    }

    function getHairLineBoxShadowFix(color) {
        return `inset 1px 1px 0 0 ${color},
 inset -1px 1px 0 0 ${color},
 inset 1px -1px 0 0 ${color},
 inset -1px -1px 0 0 ${color}`;
    }

    return {
        renderDesign,
        getContainerStyle,
        getMediaPlayerContainerStyle
    };
});
